.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.logo {
  width: 200px; /* Set the width of your logo */
  height: 200px; /* Set the height of your logo */
  animation: revealLogo 5s ease; /* Define the animation */
}

.logo-container {
  overflow: hidden; /* Hide any content outside the container */
  height: 200px; /* Set the height of the container to match your logo */
}

@keyframes revealLogo {
  from {
    transform: translateY(100%); /* Start the logo off-screen (100% down) */
  }
  to {
    transform: translateY(0%); /* End with the logo in its original position (0% down) */
  }
}



